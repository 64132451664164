import { PlaneGeometry, Color, MeshPhysicalMaterial, MeshBasicMaterial, Vector3, Geometry, Mesh, Group, PerspectiveCamera, Scene, WebGLRenderer, Math as ThreeMath, SpotLight, ExtrudeBufferGeometry, Shape, PointLight, AmbientLight, RepeatWrapping, TextureLoader, Vector2 } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { DESIGN_TEXTURES } from '@/firebase/firestore/projects/designs';
import { canvasBlob } from '@/utils/image';

let camera, scene, renderer; // THREEJS zaken
let canvas, canvasW = 1920, canvasH = 1080; // Canvas zaken tbv THREE

const loader = new TextureLoader().setPath('');

let brickMaterial;
let iconMaterial;
let zoomIconPlane;
let circle;

let rotx = 0;
let roty = 0;
let rotDragX = 0;
let rotDragY = 0;

let light1;
let blueLight;
let lightA;

const baseGroup = new Group();
const brickGroup = new Group();
let model;

let isInitialized = false;
let isAnimating = false;
let isPlaying = true;

/**
 * Start / init 3d previewer
 * @param {Object|null} textures
 */
export function startPreview (textures = null) {
    initialize(textures);
    startAnimation();
}

export function stopPreview () {
    stopAnimation();
}

/**
 * Auto play starten of stoppen
 * @param start true = play
 */
export function startStopAnim (start) {
    isPlaying = start;
}

/**
 * Set de belichtings instellingen
 * @param brightness = string kan bevatten 'bright', 'normal', 'dimmed'
 * @param warmth = string kan bevatten 'yellow', 'normal', 'blue'
 */
export function setLightSettings (brightness, warmth) {
    switch (brightness) {
        case 'bright':
            light1.intensity = 1.59;
            blueLight.intensity = 0.4;
            lightA.intensity = 0.5;
            break;
        case 'dimmed':
            light1.intensity = 0.8;
            blueLight.intensity = 0.2;
            lightA.intensity = 0.3;
            break;
        default:
            light1.intensity = 1.29;
            blueLight.intensity = 0.3;
            lightA.intensity = 0.4;
    }

    switch (warmth) {
        case 'yellow':
            light1.color = new Color('rgb(255, 235, 200)');
            blueLight.color = new Color('rgb(100, 100, 255)');
            lightA.color = new Color('rgb(255, 255, 255)');
            light1.intensity = light1.intensity * 1.1;
            blueLight.intensity = blueLight.intensity * 1.5;
            break;
        case 'blue':
            light1.color = new Color('rgb(180, 180, 255)');
            blueLight.color = new Color('rgb(100, 100, 255)');
            lightA.color = new Color('rgb(255, 255, 255)');
            blueLight.intensity = blueLight.intensity * 2.5;
            light1.intensity = light1.intensity * 1.1;
            break;
        default:
            light1.color = new Color(0xffffff);
            blueLight.color = new Color(0xaaaaff);
            lightA.color = new Color(0xffffff);
    }
}

/**
 * 'In' en 'uit' zoomen op de textuur van de kubus
 * @param {Number} textureSize - Bepaalt zoomniveau (0, 1 of 2)
 */
export function setSize (textureSize) {
    setMaterialRepeatSize(textureSize);
}

/**
 * Haal de exacte rotatie op van kubus
 */
export function getRotation () {
    return {x: rotx, y: roty};
}

/**
 * Stel exacte rotatie in van kubus
 * @param {Number} x
 * @param {Number} y
 */
export function setRotation (x, y) {
    rotx = x;
    roty = y;
}

/**
 * Drag delta doorgeven zodat de kubus kan worden geroteerd
 * @param dragDeltaX
 * @param dragDeltaY
 */
export function setDrag (dragDeltaX, dragDeltaY) {
    rotDragX = dragDeltaX;
    rotDragY = dragDeltaY;
}

/**
 * Past het slepen difinitief toe op huidige rotatie en reset drag deltas
 */
export function stopDrag () {
    setRotation(rotx + rotDragX, roty + rotDragY);

    rotDragX = 0;
    rotDragY = 0;
}

/**
 * Verberg cirkel en muis-icoon
 */
export function hideExtras () {
    circle.visible = false;
    zoomIconPlane.visible = false;
}

/**
 * Toon cirkel en muis-icoon
 */
export function showExtras () {
    circle.visible = true;
    zoomIconPlane.visible = true;
}

/**
 * set alle maps op de kubus zodat deze een textuur kan tonen
 * @param baseMap
 * @param metalnessMap
 * @param roughnessMap
 * @param normalMap
 * @param displacementMap
 */
export function setTexture ({map, metalnessMap, roughnessMap, normalMap, displacementMap}) {
    brickMaterial.map = loader.load(map);
    brickMaterial.metalnessMap = loader.load(metalnessMap);
    brickMaterial.roughnessMap = loader.load(roughnessMap);
    brickMaterial.normalMap = loader.load(normalMap);
    brickMaterial.displacementMap = loader.load(displacementMap);

    // Pas RepeatWrapping toe op alle beschikbare maps
    DESIGN_TEXTURES.forEach(mapType => {
        if (!brickMaterial[mapType]) {
            return;
        }

        brickMaterial[mapType].wrapS = RepeatWrapping;
        brickMaterial[mapType].wrapT = RepeatWrapping;
    });
}

/**
 * Geef een screenshot van huidige scene terug
 * @param {String} mimeType - Gewenste output
 * @param {Number|null} qualityArgument - Getal tussen 0 en 1, als het iets anders is wordt de default gebruikt (0.92)
 * @return {Promise<Blob>}
 */
export function capture (mimeType = 'image/png', qualityArgument = null) {
    return canvasBlob(canvas, mimeType, qualityArgument);
}

/**
 * Scene aanmaken en wat basiszaken toevoegen
 */
function initScene () {
    scene = new Scene();

    // Maak 3d UI
    let iconGeometry = new PlaneGeometry(0.15, 0.512);
    zoomIconPlane = new Mesh(iconGeometry, iconMaterial);
    zoomIconPlane.position.x = -1.95;
    zoomIconPlane.position.y = -0.8;
    zoomIconPlane.position.z = 2;

    brickGroup.add(zoomIconPlane);

    circle = createCircle(new Vector2(canvasW, canvasH));
    circle.rotation.x = Math.PI * 0.5;
    circle.rotation.z = -Math.PI * 1.242;
    circle.position.y = -0.8;
    circle.scale.x = 2.8;
    circle.scale.y = 2.8;
    circle.scale.z = 2.8;

    brickGroup.add(circle);

    baseGroup.add(brickGroup);
    baseGroup.position.z = -1.7;
    scene.add(baseGroup);
}

/**
 * init three js en video
 * @param {Object|null} textures
 */
function initialize (textures = null) {
    if (!isInitialized) {
        isInitialized = true;

        // init init zaken
        initBrickMaterial(textures);
        camera = new PerspectiveCamera(50, 1, 0.01, 20);

        initIconTexture();
        initLights();
        loadCubeModel();

        window.addEventListener('resize', updateRenderer);

        initScene();

    }

    canvas = document.getElementById('canvas-three');
    canvasW = document.getElementById('canvas-three-box').offsetWidth;
    canvasH = canvasW;//document.getElementById('canvas-three-box').offsetHeight;

    camera.aspect = canvasW / canvasH;
    camera.updateProjectionMatrix();
    camera.position.z = 6;

    if (window.innerWidth < 1200) camera.position.z = 8;

    renderer = new WebGLRenderer({
        alpha: true,
        antialias: true,
        canvas: canvas,
        preserveDrawingBuffer: true,
    });
    renderer.setSize(canvasW, canvasH);
}

/**
 * 'In' en 'Uit' zoomen op de textuur van de cubus
 */
function setMaterialRepeatSize (size) {
    switch (size) {
        case 0:
            brickMaterial.map.repeat.set(0.5, 0.5);
            break;

        case 1:
            brickMaterial.map.repeat.set(1, 1);
            break;

        default:
            brickMaterial.map.repeat.set(2, 2);
    }
}

/**
 * Update camera en renderer bij initialisatie en window resize
 */
function updateRenderer () {
    const $canvasBox = document.getElementById('canvas-three-box');
    if ($canvasBox !== null) {
        canvasW = $canvasBox.offsetWidth;
        canvasH = canvasW;//$canvasBox.offsetHeight;

        camera.aspect = canvasW / canvasH;
        camera.updateProjectionMatrix();

        renderer.setSize(canvasW, canvasH);
    }
}

/**
 * Alle tussenstappen van de animatie berekenen
 */
function updateRotation () {
    if (!isPlaying) {
        return;
    }

    rotx += 0.1;
    roty -= roty * 0.01;
}

/**
 * De render stap van THREE
 */
function _animate () {
    renderer.render(scene, camera);

    // x en y inverted
    brickGroup.rotation.y = ThreeMath.degToRad(rotx + rotDragX);
    brickGroup.rotation.x = ThreeMath.degToRad(roty + rotDragY);

    updateRotation();

    zoomIconPlane.lookAt(camera.position);

    isAnimating && requestAnimationFrame(_animate);
}

/**
 * Start de animatielus tenzij deze al actief is.
 */
function startAnimation () {
    if (isAnimating) {
        return console.log('Cannot start animation loop while already animating');
    }

    isAnimating = true;
    _animate();
}

/**
 * Stop animatielus
 */
function stopAnimation () {
    isAnimating = false;
}

/**
 * Alle lampjes op de juiste plek zetten en toevoegen aan de basegroep
 */
function initLights () {
    light1 = new SpotLight(0xffffff, 1.29, 0);
    light1.position.x = 2.5;
    light1.position.y = 2;
    light1.position.z = 4;
    light1.lookAt(new Vector3(0, 0, 0));

    baseGroup.add(light1);

    // Blauw licht van links
    blueLight = new PointLight(0xaaaaff, 0.3, 0); //78a6fa
    blueLight.position.x = -14;
    blueLight.position.y = 4;
    blueLight.position.z = -1.6;
    blueLight.lookAt(new Vector3(0, 0, 0));

    baseGroup.add(blueLight);

    lightA = new AmbientLight(0xffffff); // soft white light
    lightA.intensity = 0.4;
    baseGroup.add(lightA);
}

/**
 * Laad (en maak) iconentextuur
 */
function initIconTexture () {
    iconMaterial = new MeshBasicMaterial();
    iconMaterial.map = loader.load('/assets/img/icon3d.png');
    iconMaterial.map.anisotropy = 32; // Scherper renderen van iconen
    iconMaterial.transparent = true;
}

/**
 * Maak de cirkel rondom de cubus
 */
function createCircle (resolution) {
    let circleGeometry = new Geometry();
    for (let rotation = 0; rotation <= Math.PI * 2.0; rotation += Math.PI * 0.025) {
        circleGeometry.vertices.push(
            new Vector3(Math.cos(rotation), Math.sin(rotation), 0));
    }
    let circleLine = new MeshLine();
    circleLine.setGeometry(circleGeometry);

    //Note: resolution is *required*!
    return new Mesh(circleLine.geometry,
        new MeshLineMaterial({
            color: 'white',
            resolution,
            sizeAttenuation: 0,
            lineWidth: 3.0,
            dashSize: 3,
            gapSize: 1,
        }));
}

/**
 * Basismateriaal voor stenen maken
 * TODO vervangen door dynamische materialen
 * @param {Object|null} textures
 */
function initBrickMaterial (textures = null) {
    brickMaterial = new MeshPhysicalMaterial();

    brickMaterial.roughness = 0.9; // attenuates roughnessMap
    brickMaterial.metalness = 0.7; // attenuates metalnessMap

    textures && setTexture(textures);

    brickMaterial.displacementScale = -0.02;
    brickMaterial.normalScale.set(1, 1).multiplyScalar(1.2);
}

/**
 * De brick kubus is een glb model vanwegen de afgeronde hoeken deze moet
 * geladen worden
 */
function loadCubeModel () {
    const loaderGLTF = new GLTFLoader();

    loaderGLTF.load('/assets/models/cube.glb', function (gltf) {
        model = gltf.scene;
        model.scale.set(1, 1, 1);

        // Materiaal van de het model vervangen door dynamisch materiaal
        model.children.forEach(item => {
            if (item.type === 'Mesh') {
                item.material = brickMaterial;
            }

            if (item.isMesh === true) {
                try {
                    item.material.map.anisotropy = 32;
                } catch (e) {}
            }
        });

        brickGroup.add(model);
    });
}
