<template>
    <div id="canvas-three-box" class="the-previewer background-gradient background-gradient--sharp-halve" :class="kiosk ? '-kiosk' : ''">
        <div class="the-previewer__container">
            <v-card class="the-previewer__card pa-0" elevation="0">
                <canvas id="canvas-three" class="the-previewer__canvas" :class="isScreenGrabbing ? '-is-screen-grabbing' : ''"/>
                <v-card-title class="pb-0">
                    <v-row>
                        <v-col cols="3" class="pt-5 pl-10 pb-1">
                            <v-select v-if="!kiosk" :items="designs" v-model="currentDesign" item-text="name" item-value="id" label="Proposals" light dense solo/>
                        </v-col>
                        <v-col cols="6" class="transparant-tabs">
                            <v-tabs dark centered v-model="tab">
                                <v-tab>
                                    <svg viewBox="0 0 10.1 10.1" class="size-icon">
                                        <g>
                                            <rect x="0.1" y="0.1" class="st0" width="9.9" height="9.9"/>
                                            <line class="st0" x1="0.1" y1="2.1" x2="10" y2="2.1"/>
                                            <line class="st0" x1="0.1" y1="4.7" x2="10" y2="4.7"/>
                                            <line class="st0" x1="0.1" y1="7.3" x2="10" y2="7.3"/>
                                            <line class="st0" x1="1.3" y1="7.4" x2="1.3" y2="10"/>
                                            <line class="st0" x1="5.5" y1="4.7" x2="5.5" y2="7.3"/>
                                            <line class="st0" x1="3.9" y1="0.1" x2="3.9" y2="2"/>
                                            <line class="st0" x1="8.9" y1="7.4" x2="8.9" y2="10"/>
                                            <line class="st0" x1="1.3" y1="2" x2="1.3" y2="4.6"/>
                                            <line class="st0" x1="8.9" y1="2" x2="8.9" y2="4.6"/>
                                        </g>
                                    </svg>
                                </v-tab>
                                <v-tab>
                                    <svg viewBox="0 0 10.1 10.1" class="size-icon">
                                        <g>
                                            <rect x="0.1" y="0.1" class="st0" width="10" height="10"/>
                                            <line class="st0" x1="0.2" y1="1.6" x2="10.2" y2="1.6"/>
                                            <line class="st0" x1="0.2" y1="3" x2="10.2" y2="3"/>
                                            <line class="st0" x1="0.2" y1="4.4" x2="10.2" y2="4.4"/>
                                            <line class="st0" x1="0.2" y1="5.8" x2="10.2" y2="5.8"/>
                                            <line class="st0" x1="0.1" y1="7.2" x2="10.1" y2="7.2"/>
                                            <line class="st0" x1="0.1" y1="8.6" x2="10.1" y2="8.6"/>
                                            <line class="st0" x1="2.4" y1="0.1" x2="2.4" y2="1.6"/>
                                            <line class="st0" x1="6.4" y1="0.1" x2="6.4" y2="1.6"/>
                                            <line class="st0" x1="2.4" y1="3" x2="2.4" y2="4.4"/>
                                            <line class="st0" x1="6.4" y1="3" x2="6.4" y2="4.4"/>
                                            <line class="st0" x1="2.2" y1="5.8" x2="2.2" y2="7.2"/>
                                            <line class="st0" x1="6.3" y1="5.8" x2="6.3" y2="7.2"/>
                                            <line class="st0" x1="2.2" y1="8.6" x2="2.2" y2="10.1"/>
                                            <line class="st0" x1="6.3" y1="8.6" x2="6.3" y2="10.1"/>
                                            <line class="st0" x1="4.4" y1="1.6" x2="4.4" y2="3"/>
                                            <line class="st0" x1="8.5" y1="1.6" x2="8.5" y2="3"/>
                                            <line class="st0" x1="4.4" y1="4.4" x2="4.4" y2="5.8"/>
                                            <line class="st0" x1="8.5" y1="4.4" x2="8.5" y2="5.8"/>
                                            <line class="st0" x1="4.3" y1="7.2" x2="4.3" y2="8.6"/>
                                            <line class="st0" x1="8.4" y1="7.2" x2="8.4" y2="8.6"/>
                                        </g>
                                    </svg>
                                </v-tab>
                                <v-tab>
                                    <svg viewBox="0 0 10.1 10.1" class="size-icon">
                                        <g>
                                            <g>
                                                <rect x="0.1" y="5.1" class="st0" width="5" height="5"/>
                                                <line class="st0" x1="0.2" y1="5.8" x2="5.1" y2="5.8"/>
                                                <line class="st0" x1="0.2" y1="6.5" x2="5.1" y2="6.5"/>
                                                <line class="st0" x1="0.2" y1="7.2" x2="5.1" y2="7.2"/>
                                                <line class="st0" x1="0.2" y1="7.9" x2="5.1" y2="7.9"/>
                                                <line class="st0" x1="0.1" y1="8.6" x2="5.1" y2="8.6"/>
                                                <line class="st0" x1="0.1" y1="9.3" x2="5.1" y2="9.3"/>
                                                <line class="st0" x1="1.2" y1="5.1" x2="1.2" y2="5.8"/>
                                                <line class="st0" x1="3.2" y1="5.1" x2="3.2" y2="5.8"/>
                                                <line class="st0" x1="1.2" y1="6.5" x2="1.2" y2="7.2"/>
                                                <line class="st0" x1="3.2" y1="6.5" x2="3.2" y2="7.2"/>
                                                <line class="st0" x1="1.2" y1="7.9" x2="1.2" y2="8.6"/>
                                                <line class="st0" x1="3.2" y1="7.9" x2="3.2" y2="8.6"/>
                                                <line class="st0" x1="1.2" y1="9.3" x2="1.2" y2="10"/>
                                                <line class="st0" x1="3.2" y1="9.3" x2="3.2" y2="10"/>
                                                <line class="st0" x1="2.2" y1="5.8" x2="2.2" y2="6.5"/>
                                                <line class="st0" x1="4.3" y1="5.8" x2="4.3" y2="6.5"/>
                                                <line class="st0" x1="2.2" y1="7.2" x2="2.2" y2="7.9"/>
                                                <line class="st0" x1="4.3" y1="7.2" x2="4.3" y2="7.9"/>
                                                <line class="st0" x1="2.2" y1="8.6" x2="2.2" y2="9.3"/>
                                                <line class="st0" x1="4.2" y1="8.6" x2="4.2" y2="9.3"/>
                                            </g>
                                            <g>
                                                <rect x="0.1" y="0.1" class="st0" width="5" height="5"/>
                                                <line class="st0" x1="0.2" y1="0.8" x2="5.1" y2="0.8"/>
                                                <line class="st0" x1="0.2" y1="1.5" x2="5.1" y2="1.5"/>
                                                <line class="st0" x1="0.2" y1="2.2" x2="5.1" y2="2.2"/>
                                                <line class="st0" x1="0.2" y1="2.9" x2="5.1" y2="2.9"/>
                                                <line class="st0" x1="0.1" y1="3.6" x2="5.1" y2="3.6"/>
                                                <line class="st0" x1="0.1" y1="4.3" x2="5.1" y2="4.3"/>
                                                <line class="st0" x1="1.2" y1="0.1" x2="1.2" y2="0.8"/>
                                                <line class="st0" x1="3.2" y1="0.1" x2="3.2" y2="0.8"/>
                                                <line class="st0" x1="1.2" y1="1.5" x2="1.2" y2="2.2"/>
                                                <line class="st0" x1="3.2" y1="1.5" x2="3.2" y2="2.2"/>
                                                <line class="st0" x1="1.2" y1="2.9" x2="1.2" y2="3.6"/>
                                                <line class="st0" x1="3.2" y1="2.9" x2="3.2" y2="3.6"/>
                                                <line class="st0" x1="1.2" y1="4.3" x2="1.2" y2="5.1"/>
                                                <line class="st0" x1="3.2" y1="4.3" x2="3.2" y2="5.1"/>
                                                <line class="st0" x1="2.2" y1="0.8" x2="2.2" y2="1.5"/>
                                                <line class="st0" x1="4.3" y1="0.8" x2="4.3" y2="1.5"/>
                                                <line class="st0" x1="2.2" y1="2.2" x2="2.2" y2="2.9"/>
                                                <line class="st0" x1="4.3" y1="2.2" x2="4.3" y2="2.9"/>
                                                <line class="st0" x1="2.2" y1="3.6" x2="2.2" y2="4.3"/>
                                                <line class="st0" x1="4.2" y1="3.6" x2="4.2" y2="4.3"/>
                                            </g>
                                            <g>
                                                <rect x="5.1" y="5.1" class="st0" width="5" height="5"/>
                                                <line class="st0" x1="5.1" y1="5.8" x2="10.1" y2="5.8"/>
                                                <line class="st0" x1="5.1" y1="6.5" x2="10.1" y2="6.5"/>
                                                <line class="st0" x1="5.1" y1="7.2" x2="10.1" y2="7.2"/>
                                                <line class="st0" x1="5.1" y1="7.9" x2="10.1" y2="7.9"/>
                                                <line class="st0" x1="5.1" y1="8.6" x2="10" y2="8.6"/>
                                                <line class="st0" x1="5.1" y1="9.3" x2="10" y2="9.3"/>
                                                <line class="st0" x1="6.2" y1="5.1" x2="6.2" y2="5.8"/>
                                                <line class="st0" x1="8.2" y1="5.1" x2="8.2" y2="5.8"/>
                                                <line class="st0" x1="6.2" y1="6.5" x2="6.2" y2="7.2"/>
                                                <line class="st0" x1="8.2" y1="6.5" x2="8.2" y2="7.2"/>
                                                <line class="st0" x1="6.1" y1="7.9" x2="6.1" y2="8.6"/>
                                                <line class="st0" x1="8.1" y1="7.9" x2="8.1" y2="8.6"/>
                                                <line class="st0" x1="6.1" y1="9.3" x2="6.1" y2="10"/>
                                                <line class="st0" x1="8.1" y1="9.3" x2="8.1" y2="10"/>
                                                <line class="st0" x1="7.2" y1="5.8" x2="7.2" y2="6.5"/>
                                                <line class="st0" x1="9.2" y1="5.8" x2="9.2" y2="6.5"/>
                                                <line class="st0" x1="7.2" y1="7.2" x2="7.2" y2="7.9"/>
                                                <line class="st0" x1="9.2" y1="7.2" x2="9.2" y2="7.9"/>
                                                <line class="st0" x1="7.1" y1="8.6" x2="7.1" y2="9.3"/>
                                                <line class="st0" x1="9.1" y1="8.6" x2="9.1" y2="9.3"/>
                                            </g>
                                            <g>
                                                <rect x="5.1" y="0.1" class="st0" width="5" height="5"/>
                                                <line class="st0" x1="5.1" y1="0.8" x2="10.1" y2="0.8"/>
                                                <line class="st0" x1="5.1" y1="1.5" x2="10.1" y2="1.5"/>
                                                <line class="st0" x1="5.1" y1="2.2" x2="10.1" y2="2.2"/>
                                                <line class="st0" x1="5.1" y1="2.9" x2="10.1" y2="2.9"/>
                                                <line class="st0" x1="5.1" y1="3.6" x2="10" y2="3.6"/>
                                                <line class="st0" x1="5.1" y1="4.3" x2="10" y2="4.3"/>
                                                <line class="st0" x1="6.2" y1="0.1" x2="6.2" y2="0.8"/>
                                                <line class="st0" x1="8.2" y1="0.1" x2="8.2" y2="0.8"/>
                                                <line class="st0" x1="6.2" y1="1.5" x2="6.2" y2="2.2"/>
                                                <line class="st0" x1="8.2" y1="1.5" x2="8.2" y2="2.2"/>
                                                <line class="st0" x1="6.1" y1="2.9" x2="6.1" y2="3.6"/>
                                                <line class="st0" x1="8.1" y1="2.9" x2="8.1" y2="3.6"/>
                                                <line class="st0" x1="6.1" y1="4.3" x2="6.1" y2="5.1"/>
                                                <line class="st0" x1="8.1" y1="4.3" x2="8.1" y2="5.1"/>
                                                <line class="st0" x1="7.2" y1="0.8" x2="7.2" y2="1.5"/>
                                                <line class="st0" x1="9.2" y1="0.8" x2="9.2" y2="1.5"/>
                                                <line class="st0" x1="7.2" y1="2.2" x2="7.2" y2="2.9"/>
                                                <line class="st0" x1="9.2" y1="2.2" x2="9.2" y2="2.9"/>
                                                <line class="st0" x1="7.1" y1="3.6" x2="7.1" y2="4.3"/>
                                                <line class="st0" x1="9.1" y1="3.6" x2="9.1" y2="4.3"/>
                                            </g>
                                        </g>
                                    </svg>
                                </v-tab>
                            </v-tabs>
                        </v-col>
                        <v-col cols="3" class="pt-7 pr-10">
                            <div class="float-right">
                                <v-btn :color="playBtnColor" tile small @click="setStartStopAnim(true)">
                                    <v-icon>mdi-play</v-icon>
                                </v-btn>
                                <v-btn :color="pauseBtnColor" tile small @click="setStartStopAnim(false)">
                                    <v-icon>mdi-pause</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-title class="pb-0">
                    <v-row style="position: absolute; width: 100%;">
                        <v-col cols="12" class="pt-0 pl-10 pb-10">
                            <v-btn icon :outlined="lightBrightness == 'bright'" small @click="setLightBrightness('bright')">
                                <v-icon small>mdi-lightbulb-on-outline</v-icon>
                            </v-btn>
                            <v-btn icon :outlined="lightBrightness == 'normal'" small @click="setLightBrightness('normal')">
                                <v-icon small>mdi-lightbulb-outline</v-icon>
                            </v-btn>
                            <v-btn icon :outlined="lightBrightness == 'dimmed'" small @click="setLightBrightness('dimmed')">
                                <v-icon small>mdi-lightbulb-off-outline</v-icon>
                            </v-btn>
                            <v-btn icon :outlined="lightWarmth == 'yellow'" small color="yellow" class="ml-4" @click="setLightWarmth('yellow')">
                                <v-icon small>mdi-lightbulb-outline</v-icon>
                            </v-btn>
                            <v-btn icon :outlined="lightWarmth == 'normal'" small color="white" @click="setLightWarmth('normal')">
                                <v-icon small>mdi-lightbulb-outline</v-icon>
                            </v-btn>
                            <v-btn icon :outlined="lightWarmth == 'blue'" small color="blue" @click="setLightWarmth('blue')">
                                <v-icon small>mdi-lightbulb-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text @mousedown="mouseDown" @mouseup="mouseUp" @mousemove="mouseMove" @mouseout="mouseOut" draggable="false"/>
                <v-card-actions v-if="!kiosk">
                    <slot name="actionsLeft"/>
                    <v-spacer/>
                    <slot name="actionsRight"/>
                    <comment-add-dialog v-if="project && $inGroupArchitects() && !$isStepApproved(PROJECT_DESIGN) && !kiosk" v-slot="{ attrs, on }"
                                        :context="$t('workflow_design.context_on_design', [currentDesignName])" :project="project" :user="user" :roles="roles">
                        <v-btn v-bind="attrs" v-on="on" class="mr-5 boxed-icon" color="white" outlined tile elevation="0">
                            {{ $t('workflow_design.comments_on_design') }}
                            <v-icon right>mdi-comment-processing-outline</v-icon>
                        </v-btn>
                    </comment-add-dialog>
                    <v-btn class="boxed-icon float-right mr-8" color="primary" outlined tile @click="downloadCurrentDesign">
                        {{ $t('global.download') }}
                        <v-icon right>mdi-cloud-download-outline</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { setTexture, startPreview, startStopAnim, setLightSettings, setSize, setDrag, stopDrag, stopPreview, getRotation, setRotation, hideExtras, showExtras, capture } from '@/utils/design/preview';
import { userInGroupSuper } from '@/utils/authorization';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { DESIGN_TEXTURES } from '@/firebase/firestore/projects/designs';
import CommentAddDialog from '@/components/dialogs/CommentAddDialog';

export default {
    name: 'ThePreviewer',

    components: {CommentAddDialog},

    props: {
        /*
         * Voor doorgeven events
         *
         * Gebruik:
         *  <template><the-previewer :event-bus="eventBus"/></template>
         *  <script>
         *  ...
         *  data: () => ({
         *      eventBus: new Vue(),
         *  }),
         *  ...
         *  methods: {
         *      captureImage () {
         *          this.eventBus.$emit('eventName', {...someData});
         *      }
         *  ...
         */
        eventBus: {
            type: Object,
        },

        // Schakel diverse knoppen en mogelijkheden uit
        kiosk: {
            type: Boolean,
            default: false,
        },

        // Lijst met designs in
        designList: {
            type: Array,
        },
    },

    data: () => ({
        tab: 1,
        isPlaying: true,
        lightBrightness: 'normal',
        lightWarmth: 'normal',
        isDraging: false,
        isMouseDown: false,
        isScreenGrabbing: false,
        dragStartX: 0,
        dragStartY: 0,
        playBtnColor: 'primary',
        pauseBtnColor: 'black',
        currentDesign: null,
        currentDesignName: '',
        designs: [],
    }),

    computed: {
        ...mapGetters('authentication', {
            user: 'getUser',
            roles: 'getRoles',
        }),

        ...mapState('project', {
            project: 'boundInstance',
        }),
    },

    watch: {
        currentDesign: async function (val) {
            const design = this.designs.find(design => design.id === val);
            const objTextures = this.getTexturesFromDesign(design);
            objTextures && setTexture(objTextures);
            this.tab = 1;
            this.currentDesignName = design.name;

            // Geef het geselecteerde design door aan parent
            this.$emit('change', design);
        },

        tab: function (val) {
            setSize(val);
        },

        isPlaying: function (val) {
            if (val) {
                this.playBtnColor = 'primary';
                this.pauseBtnColor = 'black';
            } else {
                this.playBtnColor = 'black';
                this.pauseBtnColor = 'primary';
            }
        },

        project: function () {
            this.refreshDesigns();
        },
    },

    created () {
        this.eventBus?.$on('capture', () => {
            const {x, y} = getRotation();

            this.isScreenGrabbing = true;
            startStopAnim(false);
            setRotation(43, 25);
            hideExtras();

            setTimeout(async () => {
                const data = {
                    type: 'image/png',
                    image: await capture('image/png'),
                };

                this.$emit('capture', data);

                if (this.isPlaying) {
                    showExtras();
                    setRotation(x, y);
                    startStopAnim(true);
                }

                setTimeout(() => this.isScreenGrabbing = false, 100);
            }, 100);
        });
    },

    mounted () {
        // TODO: Onderzoeken of verdere optimalisatie mogelijk is. De shaders
        //       compileren lijkt bijvoorbeeld lang nodig te hebben (op
        //       Windows). Maar ook het inladen van texturen is misschien niet
        //       iedere keer noodzakelijk.

        /** @var {Object} - Bevat de Firestore Query (inclusief cursor en limiet) voor paginering van de designs */
        this.designsPage = null;

        this.refreshDesigns();
    },

    destroyed () {
        stopPreview();
    },

    methods: {
        /**
         * Download het geselecteerde ontwerp
         */
        downloadCurrentDesign () {
            const design = this.designs.find(design => design.id === this.currentDesign);
            if (design) {
                this.downloadDesign(design);
            } else {
                this.$store.dispatch('statusMessage/error', this.$t('design.unable_to_download'));
            }
        },

        /**
         * Download alle afbeeldingen vanaf de server en bied deze als 1 groot zip-bestand aan als download aan de browser
         * @param {Object} design - Designgegevens in Firebase
         */
        downloadDesign (design) {
            const zipDesign = new JSZip();

            // Let op: het resultaat in Promise.all bevat geen data, Promise all is enkel bedoeld om te zorgen dat het zip-bestand compleet is
            Promise.all(DESIGN_TEXTURES.map(texture => new Promise((resolve, reject) => {
                fetch(design.files[texture].url)
                    .then(response => {
                        if (response.status === 200 || response.status === 0) {
                            // Haal de gedownloade data op
                            // Let op: response.blob() geeft een Promise terug, niet de data
                            return Promise.resolve(response.blob());
                        } else {
                            // Breek download af
                            reject(response.statusText);
                            return Promise.reject(response.statusText);
                        }
                    })
                    // Voeg bestand to aan de zip
                    .then(download => zipDesign.file(design.files[texture].title, download))
                    // Markeer deze download als voltooid
                    .then(() => resolve())
                    // Bij enige onvoorziene problemen de download afbreken
                    .catch(() => reject());
            }))).then(() => {
                // Als alle downloads voltooid zijn is het zip-bestand gevuld en kan de download beginnen
                zipDesign.generateAsync({type: 'blob'}).then(blob => {
                    saveAs(blob, 'rodruza-design-' + design.name.replace(/[^a-zA-Z0-9-_]/, '') + '.zip');
                });
            });
        },

        setStartStopAnim (start) {
            this.isPlaying = start;
            startStopAnim(start);
        },

        setLightBrightness (brightness) {
            this.lightBrightness = brightness;
            setLightSettings(this.lightBrightness, this.lightWarmth);
        },

        setLightWarmth (warmth) {
            this.lightWarmth = warmth;
            setLightSettings(this.lightBrightness, this.lightWarmth);
        },

        mouseMove (event) {
            //console.log(event.screenX, event.screenY, event.clientX, event.clientY)

            let dragXdelta = this.dragStartX - event.screenX;
            let dragYdelta = this.dragStartY - event.screenY;

            if (this.isMouseDown) {
                if (Math.abs(dragXdelta) > 2 || Math.abs(dragYdelta) > 2) {
                    this.isDraging = true;
                    if (this.isPlaying) this.setStartStopAnim(false);
                    setDrag(-dragXdelta / 10, -dragYdelta / 10);
                }
            }
        },

        mouseDown (event) {
            this.dragStartX = event.screenX;
            this.dragStartY = event.screenY;
            this.isMouseDown = true;
        },

        mouseUp () {
            this.isMouseDown = false;
            this.isDraging = false;
            stopDrag();
        },

        mouseOut () {
            this.isMouseDown = false;
            this.isDraging = false;
            stopDrag();
        },

        /**
         * Start de preview met het eerst beschikbare design
         * @returns {Promise<>}
         */
        async startPreview () {
            if (!this.designs.length) {
                return Promise.reject();
            }

            // Door uitstellen van startPreview in resolver wordt de layout van de
            // pagina alvast bijgewerkt zodat de interface beter lijkt te reageren.
            startPreview();

            if (!this.designs.find(design => design.id === this.currentDesign)) {
                this.currentDesign = this.designs[0].id;
            }
        },

        /**
         * Haalt een lijst met texturen op uit een design
         * @returns {Object} - Lijst met texturen: {map: "https://...", roughnessMap: "https://...", ..}
         */
        getTexturesFromDesign (design) {
            if (!design?.files?.map) {
                console.error('Design "' + val + '" kon niet geladen worden');
                return;
            }

            return Object.fromEntries(Object.entries(design.files).map(entry => [entry[0], entry[1].url]));
        },

        /**
         * Haal 1 pagina op met resultaten (na scrollen)
         * @return {Promise<void>}
         */
        async fetchDesignsPage () {
            if (!this.designsPage) {
                return;
            }

            const documentSnapshots = await this.designsPage.get();
            this.designs = documentSnapshots.docs.map(design => design);
        },

        /**
         * Laad resultaten in voor weergave in dropdown
         * @return {Promise<void>}
         */
        async parseDesigns () {
            // Maak designs op voor weergave
            this.designs = this.designs.map(design => ({...design.data(), id: design.id, $snap: design}));
        },

        /**
         * Stel objecten in voor infinite scroll & live update
         * @return {Promise<>}
         */
        async initiateDesignsPage () {
            if (!this.project) {
                return;
            }

            const refProject = this.project.$ref;

            let collectionDesigns = refProject.collection('designs').where('visible', '==', true);

            if (!userInGroupSuper(this.roles)) {
                collectionDesigns = collectionDesigns.where('users', 'array-contains', this.user.uid);
            }

            // Stel de query in voor het ophalen van 1 "pagina" aan designs
            this.designsPage = collectionDesigns.orderBy('timestamp', 'desc');

            return this.designsPage;
        },

        /**
         * Herlaad designs
         * @return {Promise<void>}
         */
        async refreshDesigns () {
            // Bij vaste lijst geen update uitvoeren als de lijst al is ingeladen
            if (this.designList && this.designs.length) {
                return;
            }

            // Laad designs in afhankelijk van beschikbare bron
            if (this.designList?.length) {
                this.designs = this.designList;
            } else {
                await this.initiateDesignsPage();
                await this.fetchDesignsPage();
            }

            // Door uitstellen van startPreview wordt de layout van
            // de pagina alvast bijgewerkt zodat de interface beter lijkt te
            // reageren tijdens eerste keer openen.
            await this.parseDesigns();
            await this.startPreview();
        }
    },
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.size-icon {
    enable-background: new 0 0 10.1 10.1;
    width: 30px;
    margin-bottom: 5px;
}

.st0 {
    fill: #231F20;
    stroke: #FFFFFF;
    stroke-width: 0.2;
    stroke-miterlimit: 10;
}

.the-previewer {
    margin: -36px -48px;
    overflow: hidden;
    max-width: 100vw;
    max-height: 100%;
    height: 100% !important;

    &__card {
        position: relative;
        z-index: 2;
    }

    .v-card {
        background-color: transparent;
    }

    .v-card__title,
    .v-card__text,
    .v-card__actions {
        position: relative;
        z-index: 2;
    }

    .v-card__text {
        height: calc(100vh - 180px);
    }

    &__canvas {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.-is-screen-grabbing {
            opacity: 0;
        }
    }

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        margin: -20px;

    }

    &.-kiosk {
        margin: 0;

        .v-card__text {
            height: calc(100vh - 260px);
        }

        &__canvas {
            transform: translate(-50%, -45%);
        }
    }
}
</style>
